export const getOverviewData = () => {
    return [
        {
            // sectionImage: 'Overview/credit-one-bank-club-logo.png',
            sectionTitle: 'Ford Plaza',
            paragraph: 'The grand entrance of Allegiant Stadium offers an inviting outdoor setting for an array of event layouts.',
            area: '44,500',
            // reception: '1,350',
            // banquet: '300'
        }
    ]
}

export const getCarouselImages = () => {
    return [
		'FordPlaza/Photos/ford-plaza-bg.jpg',
        // 'FordPlaza/Photos/ford-plaza-new1.jpg',
        'FordPlaza/Photos/ford-plaza-01.jpg',
        'FordPlaza/Photos/ford-plaza-02.jpg',
        'FordPlaza/Photos/ford-plaza-03.jpg'
    ]
}

export const getLayoutImages = () => {
    return [
        'FordPlaza/Layout/layout-ford-plaza.jpg'
    ]
}
